var render = function () {
  var _this = this,
      _domProps;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.stillLoading ? _c('div', {
    staticClass: "loading-page"
  }, [_c('vue-simple-spinner', {
    attrs: {
      "size": "large",
      "message": "Fetching Data..."
    }
  })], 1) : _c('div', {
    staticClass: "min-h-screen"
  }, [_c('div', {
    staticClass: "mb-6 flex justify-between items-center"
  }, [_c('div', {
    staticClass: "flex gap-3 items-center"
  }, [_c('ArrowForward', {
    attrs: {
      "direction": "left",
      "customClass": "cursor-pointer"
    },
    on: {
      "handleClick": function handleClick() {
        _this.$router.push('/employee');
      }
    }
  }), _c('h1', {
    staticClass: "text-3xl font-bold"
  }, [_vm._v(_vm._s(_vm.$route.meta.mode) + " Employee")])], 1)]), _c('div', {
    staticClass: "my-8"
  }, [_c('TabNav', {
    attrs: {
      "tabLabels": ['Employee Data', 'Attendance'],
      "selectedTab": _vm.selected,
      "tabNavGap": 2,
      "hidden": _vm.modeForm !== 'Detail'
    },
    on: {
      "selected": _vm.setSelected
    }
  }, [_c('Tab', {
    attrs: {
      "isSelected": _vm.selected === 'Employee Data'
    }
  }, [_vm.modeForm === 'Detail' ? _c('button', {
    staticClass: "font-bold rounded-md bg-yellow-primary text-white border-yellow-primary hover:bg-yellow-300 focus:outline-none text-xs py-2 px-6 absolute -top-10 right-4",
    on: {
      "click": function click($event) {
        return _vm.toEditEmployee(_vm.idForm);
      }
    }
  }, [_vm._v(" Edit ")]) : _c('button', {
    staticClass: "font-bold rounded-md bg-yellow-primary text-white border-yellow-primary hover:bg-yellow-300 focus:outline-none text-xs py-2 px-6 absolute -top-10 right-4",
    on: {
      "click": _vm.submitData
    }
  }, [_vm._v(" " + _vm._s(_vm.modeForm === 'Edit' ? 'Update' : 'Save') + " ")]), _c('div', {
    staticClass: "flex"
  }, [_c('div', {
    staticClass: "flex flex-1 flex-col gap-6 pr-6 border-r"
  }, [_c('accordion', {
    attrs: {
      "isOpen": "",
      "enabelIconLabel": "",
      "title": "General Information"
    }
  }, [_c('div', {
    staticClass: "flex flex-col gap-6"
  }, [_c('div', [_c('h3', {
    staticClass: "text-xs font-medium neutral-500"
  }, [_vm._v("Employee's Photo")]), _vm.options.photoImage ? _c('img', {
    staticClass: "my-2 w-40",
    attrs: {
      "src": _vm.options.photoImage
    }
  }) : _c('img', {
    staticClass: "my-2",
    attrs: {
      "src": require("@/assets/images/image-ratio.jpg")
    }
  }), _c('input', {
    ref: "photoImage",
    staticClass: "hidden",
    attrs: {
      "type": "file",
      "name": "photoImage",
      "id": "photoImage",
      "accept": ".jpg, .png, .jpeg, .gif"
    },
    on: {
      "change": function change($event) {
        return _vm.handleFileSelect($event, _vm.options, 'photoImage');
      }
    }
  }), _c('Button', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.modeForm !== 'Detail',
      expression: "modeForm !== 'Detail'"
    }],
    attrs: {
      "buttonText": "Change Image",
      "type": "secondary",
      "size": "compact"
    },
    on: {
      "action": function action($event) {
        return _vm.changeImage('photoImage');
      }
    }
  })], 1), _c('TextField', {
    attrs: {
      "type": "text",
      "label": "Name (based on ID)",
      "placeholder": "Employee's fullname",
      "borderEnabled": true,
      "disabled": _vm.modeForm === 'Detail',
      "isLabelRequire": true,
      "error": _vm.formErrors['name']
    },
    model: {
      value: _vm.options.name,
      callback: function callback($$v) {
        _vm.$set(_vm.options, "name", $$v);
      },
      expression: "options.name"
    }
  }), _c('div', {
    staticClass: "gender flex flex-col gap-3"
  }, [_c('label', {
    staticClass: "text-xs font-medium neutral-500 pl-4"
  }, [_vm._v(" Gender "), _c('span', {
    staticClass: "text-red"
  }, [_vm._v("*")])]), _c('div', {
    staticClass: "flex gap-10"
  }, [_c('div', {
    staticClass: "leading-0 flex items-center gap-3 relative"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.genders.isMale,
      expression: "genders.isMale"
    }],
    staticClass: "styled-checkbox",
    attrs: {
      "type": "checkbox",
      "id": "male",
      "disabled": _vm.modeForm === 'Detail'
    },
    domProps: (_domProps = {
      "checked": true
    }, _domProps["checked"] = Array.isArray(_vm.genders.isMale) ? _vm._i(_vm.genders.isMale, null) > -1 : _vm.genders.isMale, _domProps),
    on: {
      "change": [function ($event) {
        var $$a = _vm.genders.isMale,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.genders, "isMale", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.genders, "isMale", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.genders, "isMale", $$c);
        }
      }, _vm.changeMale]
    }
  }), _c('label', {
    attrs: {
      "for": "male"
    }
  }, [_vm._v("Male")])]), _c('div', {
    staticClass: "leading-0 flex items-center gap-3 relative"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.genders.isFemale,
      expression: "genders.isFemale"
    }],
    staticClass: "styled-checkbox",
    attrs: {
      "type": "checkbox",
      "id": "female",
      "disabled": _vm.modeForm === 'Detail'
    },
    domProps: {
      "checked": Array.isArray(_vm.genders.isFemale) ? _vm._i(_vm.genders.isFemale, null) > -1 : _vm.genders.isFemale
    },
    on: {
      "change": [function ($event) {
        var $$a = _vm.genders.isFemale,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.genders, "isFemale", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.genders, "isFemale", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.genders, "isFemale", $$c);
        }
      }, _vm.changeFemale]
    }
  }), _c('label', {
    attrs: {
      "for": "female"
    }
  }, [_vm._v("Female")])])])]), _c('TextField', {
    attrs: {
      "type": "number",
      "label": "Phone Number",
      "placeholder": "Employee's phone number",
      "borderEnabled": true,
      "disabled": _vm.modeForm === 'Detail',
      "error": _vm.formErrors['phoneNumber'],
      "isLabelRequire": ""
    },
    model: {
      value: _vm.options.phoneNumber,
      callback: function callback($$v) {
        _vm.$set(_vm.options, "phoneNumber", $$v);
      },
      expression: "options.phoneNumber"
    }
  }), _c('TextField', {
    attrs: {
      "type": "text",
      "label": "ID Number",
      "placeholder": "Employee's ID number",
      "borderEnabled": true,
      "error": _vm.formErrors['idNumber'],
      "disabled": _vm.modeForm === 'Detail',
      "isLabelRequire": ""
    },
    model: {
      value: _vm.options.noKtp,
      callback: function callback($$v) {
        _vm.$set(_vm.options, "noKtp", $$v);
      },
      expression: "options.noKtp"
    }
  }), _c('TextField', {
    attrs: {
      "type": "text",
      "label": "Birth Place",
      "placeholder": "Employee's birthplace",
      "borderEnabled": true,
      "disabled": _vm.modeForm === 'Detail'
    },
    model: {
      value: _vm.options.placeBirth,
      callback: function callback($$v) {
        _vm.$set(_vm.options, "placeBirth", $$v);
      },
      expression: "options.placeBirth"
    }
  }), _c('TextField', {
    attrs: {
      "type": "date",
      "label": "Birth Date",
      "placeholder": "Employee's birthday",
      "borderEnabled": true,
      "disabled": _vm.modeForm === 'Detail'
    },
    model: {
      value: _vm.options.dateBirth,
      callback: function callback($$v) {
        _vm.$set(_vm.options, "dateBirth", $$v);
      },
      expression: "options.dateBirth"
    }
  }), _c('TextField', {
    attrs: {
      "type": "text",
      "label": "Address (based on ID)",
      "placeholder": "Employee's address",
      "borderEnabled": true,
      "disabled": _vm.modeForm === 'Detail'
    },
    model: {
      value: _vm.options.address,
      callback: function callback($$v) {
        _vm.$set(_vm.options, "address", $$v);
      },
      expression: "options.address"
    }
  }), _c('TextField', {
    attrs: {
      "type": "text",
      "label": "Domicile",
      "placeholder": "Employee's domicile",
      "borderEnabled": true,
      "disabled": _vm.modeForm === 'Detail'
    },
    model: {
      value: _vm.options.npwpDomisili,
      callback: function callback($$v) {
        _vm.$set(_vm.options, "npwpDomisili", $$v);
      },
      expression: "options.npwpDomisili"
    }
  }), _c('div', {
    staticClass: "flex flex-col gap-1"
  }, [_c('label', {
    staticClass: "text-xs font-medium neutral-500 pl-4"
  }, [_vm._v(" Religion ")]), _vm.modeForm === 'Detail' || _vm.modeForm === 'Edit' ? _c('Dropdown', {
    attrs: {
      "options": _vm.dropdownItems.religions,
      "optionLabel": "name",
      "default": _vm.selectedItems.religion,
      "placeholder": "Choose employee's religion",
      "disabled": _vm.modeForm === 'Detail'
    },
    on: {
      "change": _vm.selectReligion
    },
    model: {
      value: _vm.selectedItems.religion,
      callback: function callback($$v) {
        _vm.$set(_vm.selectedItems, "religion", $$v);
      },
      expression: "selectedItems.religion"
    }
  }) : _c('Dropdown', {
    attrs: {
      "options": _vm.dropdownItems.religions,
      "optionLabel": "name",
      "placeholder": "Choose employee's religion",
      "disabled": _vm.modeForm === 'Detail'
    },
    on: {
      "change": _vm.selectReligion
    }
  })], 1), _c('div', {
    staticClass: "flex gap-4"
  }, [_c('div', {
    staticClass: "flex flex-col gap-1 w-1/3"
  }, [_c('label', {
    staticClass: "text-xs font-medium neutral-500 pl-4"
  }, [_vm._v("Status PTKP")]), _c('Dropdown', {
    attrs: {
      "options": _vm.dropdownItems.ptkps,
      "optionLabel": "name",
      "default": _vm.selectedItems.ptkp,
      "placeholder": "Employee's PTKP status",
      "disabled": _vm.modeForm === 'Detail'
    },
    model: {
      value: _vm.selectedItems.ptkp,
      callback: function callback($$v) {
        _vm.$set(_vm.selectedItems, "ptkp", $$v);
      },
      expression: "selectedItems.ptkp"
    }
  })], 1), _c('div', {
    staticClass: "flex flex-col gap-1 w-1/3"
  }, [_c('label', {
    staticClass: "text-xs font-medium neutral-500 pl-4"
  }, [_vm._v("Status")]), _c('Dropdown', {
    attrs: {
      "options": _vm.dropdownItems.maritals,
      "optionLabel": "name",
      "default": _vm.selectedItems.marital,
      "placeholder": "Employee's marital",
      "disabled": _vm.modeForm === 'Detail'
    },
    model: {
      value: _vm.selectedItems.marital,
      callback: function callback($$v) {
        _vm.$set(_vm.selectedItems, "marital", $$v);
      },
      expression: "selectedItems.marital"
    }
  })], 1), _c('div', {
    staticClass: "flex flex-col gap-1 w-1/3"
  }, [_c('TextField', {
    attrs: {
      "type": "text",
      "label": "Number of Children",
      "placeholder": "Number of Children",
      "borderEnabled": true,
      "disabled": _vm.modeForm === 'Detail'
    },
    model: {
      value: _vm.options.child,
      callback: function callback($$v) {
        _vm.$set(_vm.options, "child", $$v);
      },
      expression: "options.child"
    }
  })], 1)])], 1)]), _c('accordion', {
    attrs: {
      "isOpen": "",
      "enabelIconLabel": "",
      "title": "Email"
    }
  }, [_c('div', {
    staticClass: "flex flex-col gap-6"
  }, [_c('TextField', {
    attrs: {
      "type": "text",
      "label": "Work Email",
      "placeholder": "Work email",
      "borderEnabled": true,
      "error": _vm.formErrors['email'],
      "disabled": _vm.modeForm === 'Detail',
      "isLabelRequire": true
    },
    model: {
      value: _vm.options.email,
      callback: function callback($$v) {
        _vm.$set(_vm.options, "email", $$v);
      },
      expression: "options.email"
    }
  }), _c('TextField', {
    attrs: {
      "type": "text",
      "label": "Personal Email",
      "placeholder": "Employee's personal email",
      "borderEnabled": true,
      "disabled": _vm.modeForm === 'Detail'
    },
    model: {
      value: _vm.options.otherEmail,
      callback: function callback($$v) {
        _vm.$set(_vm.options, "otherEmail", $$v);
      },
      expression: "options.otherEmail"
    }
  })], 1)]), _c('accordion', {
    attrs: {
      "isOpen": "",
      "enabelIconLabel": "",
      "title": "Education"
    }
  }, [_c('div', {
    staticClass: "flex flex-col gap-6"
  }, [_c('TextField', {
    attrs: {
      "type": "text",
      "label": "Last Education",
      "placeholder": "Employee's last education",
      "borderEnabled": true,
      "disabled": _vm.modeForm === 'Detail'
    },
    model: {
      value: _vm.options.lastEducation,
      callback: function callback($$v) {
        _vm.$set(_vm.options, "lastEducation", $$v);
      },
      expression: "options.lastEducation"
    }
  }), _c('TextField', {
    attrs: {
      "type": "text",
      "label": "Education Institution",
      "placeholder": "Employee's education institution name",
      "borderEnabled": true,
      "disabled": _vm.modeForm === 'Detail'
    },
    model: {
      value: _vm.options.instituteName,
      callback: function callback($$v) {
        _vm.$set(_vm.options, "instituteName", $$v);
      },
      expression: "options.instituteName"
    }
  })], 1)])], 1), _c('div', {
    staticClass: "flex flex-1 flex-col gap-6 pl-6"
  }, [_c('accordion', {
    attrs: {
      "isOpen": "",
      "enabelIconLabel": "",
      "title": "Employee's Status"
    }
  }, [_c('div', {
    staticClass: "flex flex-col gap-6"
  }, [_c('div', {
    staticClass: "flex flex-col gap-1"
  }, [_c('label', {
    staticClass: "text-xs font-medium neutral-500 pl-4"
  }, [_vm._v(" Employee ID "), _c('span', {
    staticClass: "inline-block text-neutral-300"
  }, [_vm._v("( optional / generate by system )")])]), _c('TextField', {
    attrs: {
      "type": "text",
      "placeholder": "Employee ID",
      "borderEnabled": true,
      "disabled": _vm.modeForm === 'Detail'
    },
    model: {
      value: _vm.options.nik,
      callback: function callback($$v) {
        _vm.$set(_vm.options, "nik", $$v);
      },
      expression: "options.nik"
    }
  })], 1), _c('div', {
    staticClass: "flex flex-col gap-1"
  }, [_c('label', {
    staticClass: "text-xs font-medium neutral-500 pl-4"
  }, [_vm._v(" Department "), _c('span', {
    staticClass: "text-red"
  }, [_vm._v("*")])]), _c('Dropdown', {
    attrs: {
      "options": _vm.dropdownItems.departments,
      "optionLabel": "name",
      "default": _vm.selectedItems.department,
      "placeholder": "Choose employee's department",
      "disabled": _vm.modeForm === 'Detail',
      "error": _vm.messageErrorDepartment
    },
    on: {
      "change": _vm.selectDepartment
    },
    model: {
      value: _vm.selectedItems.department,
      callback: function callback($$v) {
        _vm.$set(_vm.selectedItems, "department", $$v);
      },
      expression: "selectedItems.department"
    }
  })], 1), _c('TextField', {
    attrs: {
      "type": "text",
      "label": "Role",
      "placeholder": "Employee's role",
      "borderEnabled": true,
      "disabled": _vm.modeForm === 'Detail'
    },
    model: {
      value: _vm.options.role,
      callback: function callback($$v) {
        _vm.$set(_vm.options, "role", $$v);
      },
      expression: "options.role"
    }
  }), _c('div', {
    staticClass: "flex flex-col gap-1"
  }, [_c('label', {
    staticClass: "text-xs font-medium neutral-500 pl-4"
  }, [_vm._v(" Grade ")]), _c('Dropdown', {
    attrs: {
      "options": _vm.dropdownItems.grades,
      "optionLabel": "name",
      "default": _vm.selectedItems.grade,
      "placeholder": "Choose employee's grade",
      "disabled": _vm.modeForm === 'Detail'
    },
    model: {
      value: _vm.selectedItems.grade,
      callback: function callback($$v) {
        _vm.$set(_vm.selectedItems, "grade", $$v);
      },
      expression: "selectedItems.grade"
    }
  })], 1), _c('div', {
    staticClass: "flex flex-col gap-1"
  }, [_c('label', {
    staticClass: "text-xs font-medium neutral-500 pl-4"
  }, [_vm._v(" Direct Supervisor "), _c('span', {
    staticClass: "text-red"
  }, [_vm._v("*")])]), _c('Dropdown', {
    attrs: {
      "options": _vm.dropdownItems.supervisors,
      "optionLabel": "name",
      "default": _vm.selectedItems.spv,
      "placeholder": "Choose employee's supervisor",
      "disabled": _vm.modeForm === 'Detail',
      "error": _vm.messageErrorSupervisor,
      "enableSearch": ""
    },
    on: {
      "search": _vm.getListSupervisor,
      "change": _vm.selectSupervisor
    },
    model: {
      value: _vm.selectedItems.spv,
      callback: function callback($$v) {
        _vm.$set(_vm.selectedItems, "spv", $$v);
      },
      expression: "selectedItems.spv"
    }
  })], 1), _c('div', {
    staticClass: "flex flex-col gap-1"
  }, [_c('label', {
    staticClass: "text-xs font-medium neutral-500 pl-4"
  }, [_vm._v(" Level ")]), _c('Dropdown', {
    attrs: {
      "optionLabel": "name",
      "options": _vm.dropdownItems.levels,
      "default": _vm.selectedItems.level,
      "placeholder": "Choose employee's level",
      "disabled": _vm.modeForm === 'Detail'
    },
    model: {
      value: _vm.selectedItems.level,
      callback: function callback($$v) {
        _vm.$set(_vm.selectedItems, "level", $$v);
      },
      expression: "selectedItems.level"
    }
  })], 1), _c('div', {
    staticClass: "flex flex-col gap-1"
  }, [_c('label', {
    staticClass: "text-xs font-medium neutral-500 pl-4"
  }, [_vm._v(" Status "), _c('span', {
    staticClass: "text-red"
  }, [_vm._v("*")])]), _c('Dropdown', {
    attrs: {
      "options": _vm.dropdownItems.statuses,
      "optionLabel": "name",
      "default": _vm.selectedItems.status,
      "placeholder": "Choose employee's status",
      "disabled": _vm.modeForm === 'Detail',
      "error": _vm.formErrors['statusId']
    },
    on: {
      "change": function change($event) {
        return _vm.selectStatus($event);
      }
    },
    model: {
      value: _vm.selectedItems.status,
      callback: function callback($$v) {
        _vm.$set(_vm.selectedItems, "status", $$v);
      },
      expression: "selectedItems.status"
    }
  })], 1), _c('TextField', {
    attrs: {
      "type": "date",
      "label": "Join Date",
      "placeholder": "Employee's join date",
      "borderEnabled": true,
      "disabled": _vm.modeForm === 'Detail',
      "isLabelRequire": true,
      "error": _vm.formErrors['firstJoinDate']
    },
    on: {
      "input": function input($event) {
        return _vm.calculateEndContractDate();
      }
    },
    model: {
      value: _vm.options.firstJoinDate,
      callback: function callback($$v) {
        _vm.$set(_vm.options, "firstJoinDate", $$v);
      },
      expression: "options.firstJoinDate"
    }
  }), _c('TextField', {
    attrs: {
      "type": "date",
      "label": "End Contract Date",
      "placeholder": "Employee's end contract date",
      "borderEnabled": true,
      "disabled": _vm.modeForm === 'Detail'
    },
    model: {
      value: _vm.options.lastContractDate,
      callback: function callback($$v) {
        _vm.$set(_vm.options, "lastContractDate", $$v);
      },
      expression: "options.lastContractDate"
    }
  })], 1)]), _c('accordion', {
    attrs: {
      "isOpen": "",
      "enabelIconLabel": "",
      "title": "BPJS"
    }
  }, [_c('div', {
    staticClass: "flex flex-col gap-6"
  }, [_c('TextField', {
    attrs: {
      "type": "text",
      "label": "BPJS Ketenagakerjaan",
      "placeholder": "Employee's BPJS Ketenagakerjaan",
      "borderEnabled": true,
      "disabled": _vm.modeForm === 'Detail'
    },
    model: {
      value: _vm.options.bpjsTkNo,
      callback: function callback($$v) {
        _vm.$set(_vm.options, "bpjsTkNo", $$v);
      },
      expression: "options.bpjsTkNo"
    }
  }), _c('TextField', {
    attrs: {
      "type": "text",
      "label": "BPJS Kesehatan",
      "placeholder": "Employee's BPJS Kesehatan",
      "borderEnabled": true,
      "disabled": _vm.modeForm === 'Detail'
    },
    model: {
      value: _vm.options.bpjsKesehatanNo,
      callback: function callback($$v) {
        _vm.$set(_vm.options, "bpjsKesehatanNo", $$v);
      },
      expression: "options.bpjsKesehatanNo"
    }
  })], 1)]), _c('accordion', {
    attrs: {
      "isOpen": "",
      "enabelIconLabel": "",
      "title": "NPWP"
    }
  }, [_c('div', {
    staticClass: "flex flex-col gap-6"
  }, [_c('TextField', {
    attrs: {
      "type": "text",
      "label": "NPWP ID",
      "placeholder": "Employee's NPWP ID",
      "borderEnabled": true,
      "disabled": _vm.modeForm === 'Detail'
    },
    model: {
      value: _vm.options.npwpNo,
      callback: function callback($$v) {
        _vm.$set(_vm.options, "npwpNo", $$v);
      },
      expression: "options.npwpNo"
    }
  }), _c('TextField', {
    attrs: {
      "type": "text",
      "label": "Name (based on NPWP)",
      "placeholder": "Employee's fullname",
      "borderEnabled": true,
      "disabled": _vm.modeForm === 'Detail'
    },
    model: {
      value: _vm.options.npwpName,
      callback: function callback($$v) {
        _vm.$set(_vm.options, "npwpName", $$v);
      },
      expression: "options.npwpName"
    }
  }), _c('TextField', {
    attrs: {
      "type": "text",
      "label": "Address (based on NPWP)",
      "placeholder": "Employee's address",
      "borderEnabled": true,
      "disabled": _vm.modeForm === 'Detail'
    },
    model: {
      value: _vm.options.npwpAddress,
      callback: function callback($$v) {
        _vm.$set(_vm.options, "npwpAddress", $$v);
      },
      expression: "options.npwpAddress"
    }
  })], 1)]), _c('accordion', {
    attrs: {
      "isOpen": "",
      "enabelIconLabel": "",
      "title": "Emergency Contact"
    }
  }, [_c('div', {
    staticClass: "flex flex-col gap-6"
  }, [_c('TextField', {
    attrs: {
      "type": "text",
      "label": "Emergency Contact Name",
      "placeholder": "Employee's emergency contact name",
      "borderEnabled": true,
      "disabled": _vm.modeForm === 'Detail'
    },
    model: {
      value: _vm.options.contactName,
      callback: function callback($$v) {
        _vm.$set(_vm.options, "contactName", $$v);
      },
      expression: "options.contactName"
    }
  }), _c('TextField', {
    attrs: {
      "type": "text",
      "label": "Relationship",
      "placeholder": "Relationship with employee",
      "borderEnabled": true,
      "disabled": _vm.modeForm === 'Detail'
    },
    model: {
      value: _vm.options.contactRelation,
      callback: function callback($$v) {
        _vm.$set(_vm.options, "contactRelation", $$v);
      },
      expression: "options.contactRelation"
    }
  }), _c('TextField', {
    attrs: {
      "type": "number",
      "label": "Emergency Contact Phone Number",
      "placeholder": "Employee's emergency contact phone number",
      "borderEnabled": true,
      "disabled": _vm.modeForm === 'Detail'
    },
    model: {
      value: _vm.options.contactPhone,
      callback: function callback($$v) {
        _vm.$set(_vm.options, "contactPhone", $$v);
      },
      expression: "options.contactPhone"
    }
  }), _c('TextField', {
    attrs: {
      "type": "text",
      "label": "Emergency Contact Address",
      "placeholder": "Employee's emergency contact address",
      "borderEnabled": true,
      "disabled": _vm.modeForm === 'Detail'
    },
    model: {
      value: _vm.options.contactAddress,
      callback: function callback($$v) {
        _vm.$set(_vm.options, "contactAddress", $$v);
      },
      expression: "options.contactAddress"
    }
  })], 1)])], 1)])]), _c('Tab', {
    attrs: {
      "isSelected": _vm.selected === 'Attendance'
    }
  }, [_c('div', {
    staticClass: "flex items-stretch"
  }, [_c('div', {
    staticClass: "flex flex-col gap-6 pr-6 w-1/2 shadow-border-r flex-shrink-0"
  }, [_c('div', {
    staticClass: "flex items-center"
  }, [_c('div', {
    staticClass: "mr-4"
  }, [_c('p', {
    staticClass: "text-sm my-2 text-gray-500"
  }, [_vm._v("Start date")]), _c('TextField', {
    staticClass: "w-36",
    attrs: {
      "type": "date",
      "borderEnabled": "",
      "placeholder": "Select Date"
    },
    model: {
      value: _vm.options.startDate,
      callback: function callback($$v) {
        _vm.$set(_vm.options, "startDate", $$v);
      },
      expression: "options.startDate"
    }
  })], 1), _c('span', {
    staticClass: "mt-8 -ml-2"
  }, [_vm._v("-")]), _c('div', {
    staticClass: "ml-2"
  }, [_c('p', {
    staticClass: "text-sm my-2 text-gray-500"
  }, [_vm._v("End date")]), _c('TextField', {
    staticClass: "w-36",
    attrs: {
      "type": "date",
      "borderEnabled": "",
      "placeholder": "Select Date"
    },
    model: {
      value: _vm.options.endDate,
      callback: function callback($$v) {
        _vm.$set(_vm.options, "endDate", $$v);
      },
      expression: "options.endDate"
    }
  })], 1), _c('div', {
    staticClass: "flex justify-start"
  }, [_c('button', {
    staticClass: "w-20 p-2 mx-3 mt-8 font-semibold inline bg-yellow text-white text-center rounded-lg",
    on: {
      "click": function click($event) {
        return _vm.onSearch();
      }
    }
  }, [_vm._v("Apply")]), _c('button', {
    staticClass: "p-2 bg-yellow-secondary mt-8 rounded-lg inline",
    on: {
      "click": function click($event) {
        return _vm.resetFilter();
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require("../../assets/images/refresh.png"),
      "alt": ""
    }
  })])])]), _c('vue-good-table', {
    attrs: {
      "mode": "remote",
      "pagination-options": {
        enabled: true,
        mode: 'pages',
        perPage: _vm.perPage,
        position: 'bottom',
        perPageDropdown: [5, 7, 10],
        dropdownAllowAll: false,
        nextLabel: 'next',
        prevLabel: 'prev',
        rowsPerPageLabel: 'Rows per page',
        ofLabel: 'of',
        pageLabel: 'page',
        // for 'pages' mode
        allLabel: 'All'
      },
      "totalRows": _vm.totalRecords,
      "rows": _vm.data,
      "columns": _vm.columns2,
      "row-style-class": _vm.rowStyleClassFn
    },
    on: {
      "on-page-change": _vm.onPageChange,
      "on-per-page-change": _vm.onPageChange
    },
    scopedSlots: _vm._u([{
      key: "table-row",
      fn: function fn(props) {
        return [props.column.field === 'checkIn' ? _c('div', [_c('span', {
          staticStyle: {
            "display": "block",
            "font-size": "14px",
            "color": "#333"
          }
        }, [_vm._v(" " + _vm._s(_vm.formatDate(props.formattedRow[props.column.field]).time ? _vm.formatDate(props.formattedRow[props.column.field]).time : '-') + " ")])]) : props.column.field === 'checkOut' ? _c('div', [_c('span', {
          staticStyle: {
            "display": "block",
            "white-space": "nowrap"
          }
        }, [_vm._v(" " + _vm._s(_vm.formatDate(props.formattedRow[props.column.field]).time ? _vm.formatDate(props.formattedRow[props.column.field]).time : '-') + " ")])]) : _c('span', [_vm._v(" " + _vm._s(props.formattedRow[props.column.field]) + " ")])];
      }
    }])
  })], 1), _c('div', {
    staticClass: "p-6 w-1/2 flex-shrink-0"
  }, [_c('div', {
    staticClass: "flex justify-between"
  }, [_c('p', {
    staticClass: "font-bold -mt-8"
  }, [_vm._v("Attendance Summary")]), _c('p', {
    staticClass: "-mt-8"
  }, [_vm._v(_vm._s(_vm.label))])]), _c('div', {
    staticClass: "my-4 grid grid-cols-2 max-h-screen"
  }, [(_vm.pieData ? _vm.pieData : false) ? _c('div', {
    staticClass: "relative"
  }, [_c('pie-chart', {
    attrs: {
      "data": _vm.pieData,
      "options": {}
    }
  })], 1) : _c('div', {
    staticClass: "flex justify-center items-center"
  }, [_c('p', {
    staticClass: "mt-24 ml-20"
  }, [_vm._v("Data not found")])]), _c('div', {
    staticClass: "pl-6"
  }, _vm._l(_vm.pieData, function (data, idx) {
    return _c('div', {
      key: idx,
      staticClass: "flex items-center my-2"
    }, [_c('div', {
      staticClass: "mr-2 w-7 h-7 rounded-sm",
      style: "background: ".concat(data.color)
    }), _c('div', [_c('p', [_vm._v(_vm._s(data.title))]), _c('p', {
      staticClass: "text-lg font-bold"
    }, [_vm._v(_vm._s(data.count))])])]);
  }), 0)])])])])], 1)], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }